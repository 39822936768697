<template>
  <div class="ProductCenter-container">
    <!-- 首页轮播图 -->
    <div class="ProductCenter-container-header">
      <SwiperCom
        :srcAry="imgAray"
        :imgWidth="1920"
        :imgheight="1080"
        :swiperType1="true"
        :swiperType2="false"
      />
    </div>
    <!-- 产品轮播图 -->
    <div class="ProductCenter-container-product">
      <h3 class="column-title">
        <span
          >产品中心<br />
          Product Center</span
        >
      </h3>
      <ProductSwiper
        :srcAry="imgAray1"
        :imgWidth="192"
        :imgheight="108"
        :swiperDelay="1500"
        :productNumber="3"
        :swiperType1="false"
        :swiperType2="true"
        :isButton="false"
      />
    </div>
  </div>
</template>

<script>
import SwiperCom from "../components/SwiperCom/index.vue";
import ProductSwiper from "../components/ProductSwiper/index.vue";
export default {
  components: {
    SwiperCom,
    ProductSwiper,
  },
  data() {
    return {
      imgAray: [
        { imgUrl: require("../assets/product/title1.jpg") },
        { imgUrl: require("../assets/product/title2.jpg") },
        { imgUrl: require("../assets/product/title3.jpg") },
        { imgUrl: require("../assets/product/title4.jpg") },
        { imgUrl: require("../assets/product/title5.jpg") },
        { imgUrl: require("../assets/product/title6.jpg") },
      ],
      imgAray1: [
        { imgUrl: require("../assets/product/productPicture/1.jpg") },
        { imgUrl: require("../assets/product/productPicture/2.jpg") },
        { imgUrl: require("../assets/product/productPicture/3.jpg") },
        { imgUrl: require("../assets/product/productPicture/4.jpg") },
        { imgUrl: require("../assets/product/productPicture/5.jpg") },
        { imgUrl: require("../assets/product/productPicture/6.jpg") },
        { imgUrl: require("../assets/product/productPicture/7.jpg") },
        { imgUrl: require("../assets/product/productPicture/8.jpg") },
        { imgUrl: require("../assets/product/productPicture/9.jpg") },
        { imgUrl: require("../assets/product/productPicture/10.jpg") },
        { imgUrl: require("../assets/product/productPicture/11.jpg") },
        { imgUrl: require("../assets/product/productPicture/12.jpg") },
        { imgUrl: require("../assets/product/productPicture/13.jpg") },
        { imgUrl: require("../assets/product/productPicture/14.jpg") },
        { imgUrl: require("../assets/product/productPicture/15.jpg") },
        { imgUrl: require("../assets/product/productPicture/16.jpg") },
        { imgUrl: require("../assets/product/productPicture/17.jpg") },
        { imgUrl: require("../assets/product/productPicture/18.jpg") },
        { imgUrl: require("../assets/product/productPicture/19.jpg") },
        { imgUrl: require("../assets/product/productPicture/20.jpg") },
        { imgUrl: require("../assets/product/productPicture/21.jpg") },
        { imgUrl: require("../assets/product/productPicture/22.jpg") },
        { imgUrl: require("../assets/product/productPicture/23.jpg") },
      ],
    };
  },
};
</script>

<style scoped>
.ProductCenter-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.column-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 768px) {
  .column-title {
    font-size: 12px;
  }
}
.column-title span {
  background: #b90d0d;
  display: inline-block;
  padding: 5px 60px;
  border-radius: 40px;
  position: relative;
}
.column-title span:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  display: block;
  position: absolute;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
</style>
