<template>
  <div class="homePage-container">
    <!-- 轮播图 -->
    <div class="ssWeb-container-first">
      <SwiperCom
        :srcAry="imgAray"
        :imgWidth="1920"
        :imgheight="1080"
        :swiperType1="true"
        :swiperType2="false"
      />
      <!-- <AlonePage :srcAry="imgAray" :imgWidth="1920" :imgheight="1080" /> -->
    </div>

    <!-- 生机盎然，品质保障 -->
    <div class="ssWeb-container-second">
      <h3 class="column-title">
        <span
          >生机盎然，品质保障<br />
          Full Of Vitality Quality Assurance</span
        >
      </h3>
      <div class="ssWeb-container-second-content">
        <img src="../assets/home/ql001.png" alt="" srcset="" />
        <img src="../assets/home/ql002.png" alt="" srcset="" />
        <img src="../assets/home/ql003.png" alt="" srcset="" />
        <img src="../assets/home/ql004.png" alt="" srcset="" />
      </div>
    </div>
    <!-- 古法慢酿,酱香醇厚 -->

    <div class="ssWeb-container-last">
      <el-divider></el-divider>
      <h3 class="column-title">
        <span
          >古法慢酿,酱香醇厚<br />
          The Ancient Slow Brewing Sauce Is Mellow</span
        >
      </h3>
      <!-- <AlonePage :srcAry="imgAray1" :imgWidth="1920" :imgheight="1080" /> -->
      <SwiperCom
        :srcAry="imgAray1"
        :imgWidth="1920"
        :imgheight="1080"
        :swiperType1="true"
        :swiperType2="false"
        :isButton="true"
      />
      <!-- <SwiperCom /> -->
    </div>
  </div>
</template>

<script>
// import AlonePage from "../components/AlonePage/index.vue";
import SwiperCom from "../components/SwiperCom";
export default {
  components: {
    // AlonePage,
    SwiperCom,
  },
  data() {
    return {
      imgAray: [
        { imgUrl: require("../assets/banner/bn9.jpg") },
        { imgUrl: require("../assets/banner/bn8.jpg") },
        { imgUrl: require("../assets/banner/bn10.jpg") },
      ],
      imgAray1: [
        { imgUrl: require("../assets/home/bn11.jpg") },
        { imgUrl: require("../assets/home/bn12.jpg") },
        { imgUrl: require("../assets/home/bn13.jpg") },
        { imgUrl: require("../assets/home/bn14.jpg") },
        { imgUrl: require("../assets/home/bn15.jpg") },
      ],
    };
  },
};
</script>

<style>
.homePage-container,
.ssWeb-container-second {
  width: 100%;
}
.ssWeb-container-second {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.ssWeb-container-second-content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ssWeb-container-second-content img {
  width: 25%;
}
.ssWeb-container-last {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.column-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 768px) {
  .column-title {
    font-size: 12px;
  }
}
.column-title span {
  background: #b90d0d;
  display: inline-block;
  padding: 5px 60px;
  border-radius: 40px;
  position: relative;
}
.column-title span:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  display: block;
  position: absolute;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
</style>
