<template>
  <div class="companyIntroduction-container">
    <div class="companyIntroduction-containe-header">
      <img src="../assets/company/zjss001.jpg" />
    </div>
    <div class="companyIntroduction-containe-header-video">
      <video
        muted="true"
        width="100%"
        playsinline=""
        x5-video-player-type="h5"
        poster="../assets/banner/bn9.jpg"
        onended="myfun()"
        src="http://sstwp.com:90/cms1/imgs/video/video1-4.mp4"
        type="video/mp4"
        autoplay="true"
      ></video>
    </div>
    <div class="companyIntroduction-containe-center">
      <el-container>
        <el-aside class="companyIntroduction-containe-center-left" width="20%">
        </el-aside>
        <el-main class="companyIntroduction-containe-center-right">
          <div class="companyIntroduction-containe-center-right-introduction">
            <h3 class="column-title">
              <span>企业文化 <br />corporate culture</span>
            </h3>
            <div class="companyIntroduction-containe-center-right-introduction-text">
              <p>
                四川生生护国调味品有限公司（简称“生生护国”）系
                “三缸文化”之乡（浙江绍兴）娄浩川先生1930年始创于成都，时名“上海酱园”，后更名为“上海生生酱园”；在1956年的“一化三改造”中与17家酱园融合组成“成都生生酿造厂”，1965年支援“三线建设”迁至西昌市，2023年8月成为四川元景达食品有限公司控股子公司，纳入泸州老窖集团食品战略版图。时光印记，美味流传，“生生护国”的系列产品早已成为川西地区人们舌尖上的味觉基因和记忆中的家乡味道。
              </p>
              <p>
                九十余载风雨兼程，生生护国深耕川西高原，汲取天地精华、独享自然馈赠，保护并传承先辈自然配方，探索独有的高原酿制技艺，孕育出独特的产品风味和口感，实现了传统工艺与现代技术设备的对接融合，是中国西部高端调味品研发、生产、销售、创新的现代化科技型标杆企业，主要产品包括：酱油、食醋、甜面酱、料酒和复合调料等。
              </p>
              <p>
                作为“正宗四川麸醋工艺传承者”“高盐固态酱油标准制定者”及“高原酿造专家”，生生护国以“传承传统工艺，分享自然美味”为使命，秉持“正直诚信，用户第一、客户优先、共创造同分享”的核心价值观，坚持“以质为本、以味为魂、凭信而立”的经营思想，创新奋进，跨越发展，努力打造成为中国传统酿造行业中责任担当、值得信赖、受人尊敬的百年企业。
              </p>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="companyIntroduction-containe-center-right-history">
            <h3 class="column-title">
              <span>企业发展史 <br />corporate history</span>
            </h3>
            <img src="../assets/history.jpg" alt="" /></div
        ></el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banerIndex: 1,
    };
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.companyIntroduction-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.companyIntroduction-containe-header {
  width: 100%;
  display: none;
}
.companyIntroduction-containe-header img {
  width: 100%;
}
.companyIntroduction-containe-header-video,
.companyIntroduction-containe-header-video video {
  width: 100%;
}
.companyIntroduction-containe-center {
  width: 100%;
}
.companyIntroduction-containe-center-left {
  /* width: 20%; */
  /* border: 1px solid black; */
  display: none;
}
.companyIntroduction-containe-center-right {
  width: 79%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);

  /* border: 1px solid black; */
}
.companyIntroduction-containe-center-right-introduction {
  width: 100%;
  margin-bottom: 50px;

  /* border: 1px solid black; */
}

.companyIntroduction-containe-center-right-introduction-text {
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  transition: all 0.5s ease;
}
.companyIntroduction-containe-center-right-introduction-text:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.4);
}
.companyIntroduction-containe-center-right-introduction p {
  text-indent: 2em;
  font-size: 14px;
  line-height: 28px;
  font-family: Arial, Helvetica, sans-serif;
  /* text-align: left; */
  text-align: start;
}
.companyIntroduction-containe-center-right-history {
  width: 100%;
  /* border: 1px solid black; */
}
.companyIntroduction-containe-center-right-history img {
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  translate: all 5s line;
}
.companyIntroduction-containe-center-right-history img:hover {
  /* transform: scale(1.05); 放大元素 */
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.4); /* 添加阴影效果 */
}
.column-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 768px) {
  .column-title {
    font-size: 12px;
    line-height: 20px;
  }
  .companyIntroduction-containe-center-right-introduction p {
    font-size: 10px;
    line-height: 12px;
  }
}
.column-title span {
  background: #b90d0d;
  display: inline-block;
  padding: 5px 60px;
  border-radius: 40px;
  position: relative;
}
.column-title span:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  display: block;
  position: absolute;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
</style>
