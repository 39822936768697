<template>
  <div class="homePage-container">
    <el-menu
      class="el-menu-demo homePage-container-content"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <img src="../assets/logo.png" alt="" srcset="" />
      <el-menu-item class="homePage-container-content-item" index="1"
        >网站首页</el-menu-item
      >
      <el-menu-item class="homePage-container-content-item" index="2"
        >走进生生</el-menu-item
      >
      <el-menu-item class="homePage-container-content-item" index="3"
        >产品中心</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerIndex: 1,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.bannerIndex = key;
      this.$emit("child-message", key);
    },
  },
};
</script>

<style scoped>
.homePage-container-content {
  display: flex;
  justify-content: center;
  width: 100%;
}
.homePage-container-content img {
  height: 80px;
  padding-right: 180px;
  display: inline-block;
  vertical-align: middle;
}
.homePage-container-content-item {
  height: 80px;
  line-height: 80px;
  /* padding: 0 8px 0 8px; */
  font-size: 18px;
  clear: both;
}
/* 移动端样式 */
@media (max-width: 768px) {
  .homePage-container-content img {
    /* 移动端布局样式 */
    height: 40px;
    padding-right: 0px;
  }
  .homePage-container-content-item {
    height: 40px;
    line-height: 40px;
    padding: 0 8px 0 8px;
    font-size: 12px;
  }
}
</style>
